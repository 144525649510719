import { PropsWithChildren } from "react";

export default function CalculatorContainer(
  props: JSX.IntrinsicElements["div"] & PropsWithChildren
) {
  const { className, children, ...divProps } = props;

  return (
    <div
      id="calculator-container"
      className={
        "flex w-full min-w-[20rem] max-w-[30rem] flex-col gap-8 rounded-[3px] bg-white py-7 sm:px-5 " +
        className
      }
      {...divProps}
    >
      {children}
      </div>
  );
}
