import { PropsWithChildren } from "react";

export function Anchor(props: PropsWithChildren<JSX.IntrinsicElements["a"]>) {
    return (
      <a
        className="text-info underline"
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      ></a>
    );
  }