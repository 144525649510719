"use client";

import { SupportedCollateralId } from "shared/build/src/SupportedCurrencies";
import { useState, useEffect } from "react";
import { BigFloat } from "shared/build/src/utils/BigFloat";
import { getDisplayValues, getSliderValues } from "../functions";
import { useRates } from "src/conversion/hooks";
import { useCalculatorRates } from "../hooks/useCalculatorRates";
import { getLtvValues, getLtv } from "src/loan/LoanFunctions";
import { useCollateral } from "../hooks/useCollateral";
import { useLoanAmount } from "../hooks/useLoanAmount";
import { useLoanDetails } from "src/loan/components/details/hooks";
import { formatToDisplayString } from "src/formatting/functions";
import { CalculatorProps } from "../CreateLoanCalculator";

export function useCalculator(
  props: Pick<
    CalculatorProps,
    | "adapter"
    | "loanFee"
    | "collateralCurrency"
    | "onCollateralChanged"
    | "loanCurrencyCollection"
  > & { isHomePageCalculator?: boolean }
) {
  const {
    adapter,
    loanFee,
    isHomePageCalculator,
    collateralCurrency,
    onCollateralChanged,
  } = props;

  // conversion & currency hook values
  const loanCurrencyCollection = useRates(props.loanCurrencyCollection);

  // allows only dai on non-homepage calculator
  const loanCurrencies = loanCurrencyCollection
    .map((asset) => asset.assetId)
    .filter(
      (currency) =>
        isHomePageCalculator || (!isHomePageCalculator && currency === "DAI")
    );

  const noDai = !loanCurrencies.includes("DAI");

  const [loanCurrency, setLoanCurrency] = useState(loanCurrencies[0]);

  // rates
  const {
    loanToDaiRate,
    collateralToDaiRate,
    collateralToLoanRate,
    daiToNokRate,
    daiToUsdRate,
  } = useCalculatorRates({
    initialCollateralToDaiRate: adapter.price,
    loanCurrency,
    loanCurrencyCollection,
  });

  // ltv values
  const ltvValues = getLtvValues(adapter.liquidationTreshold);

  const maxLtv = ltvValues.POOR;

  const liqLtv = ltvValues.LIQUIDATED;

  // input field values
  const {
    collateral,
    collateralError,
    collateralBigFloat,
    setCollateral,
    onCollateralChange,
    catchCollateralErrors,
    initialCollateral,
    getMinCollateral,
    collateralConversionText,
  } = useCollateral({
    collateralCurrency,
  });

  const changeCollateral = (collateral: SupportedCollateralId) => {
    setCollateral(initialCollateral[collateral].formatAndRound(0).toString());
    if (onCollateralChanged) onCollateralChanged(collateral);
  };

  const minCollateral = getMinCollateral({
    adapterPrice: adapter.price,
    debtFloor: adapter.debtFloor,
    liquidationThreshold: adapter.liquidationTreshold,
  });

  const {
    loanAmount,
    loanAmountBigFloat,
    loanAmountError,
    setLoanAmount,
    catchLoanAmountErrors,
    onLoanAmountChange,
    loanAmountInDai,
    loanAmountInDaiPlusFee,
    loanAmountPlusFee,
    minLoanInLoanCurrency,
    maxLoanInLoanCurrency,
    currencySymbolBeforeInput,
    currencySymbolAfterInput,
  } = useLoanAmount({
    initialCollateral: initialCollateral[collateralCurrency],
    collateralToDaiRate,
    loanToDaiRate,
    loanFee,
    collateralBigFloat,
    debtFloor: adapter.debtFloor,
    loanCurrency,
    maxLtv,
  });

  useEffect(() => {
    if (loanAmountBigFloat.greaterThan(maxLoanInLoanCurrency)) {
      setLoanAmount(formatToDisplayString(maxLoanInLoanCurrency));
    } else if (loanAmountBigFloat.lessThan(minLoanInLoanCurrency)) {
      setLoanAmount(formatToDisplayString(minLoanInLoanCurrency));
    }
  }, [collateralCurrency, loanCurrency, collateral]);

  const ltv =
    loanAmountInDai.equals(BigFloat.zero()) ||
    collateralBigFloat.equals(BigFloat.zero())
      ? BigFloat.zero()
      : getLtv({
          debt: loanAmountInDaiPlusFee,
          collateral: collateralBigFloat,
          price: collateralToDaiRate,
        });

  const { liquidationPrice, maxLtvTooltipText, liqLtvTooltipText } =
    getDisplayValues({
      maxLoanInLoanCurrency,
      loanAmountBigFloat,
      collateralBigFloat,
      collateralToLoanRate,
      liqLtv,
      loanCurrency,
    });

  // error handling
  const errors = collateralError !== null || loanAmountError !== null;

  const catchErrors = (availableCollateralWalletBalance?: BigFloat) => {
    return {
      loanAmountError: catchLoanAmountErrors(),
      collateralError: catchCollateralErrors(
        minCollateral,
        availableCollateralWalletBalance
      ),
    };
  };

  // slider
  const { sliderPos, sliderClassName } = getSliderValues({
    loanAmount: loanAmountBigFloat,
    maxLoan: maxLoanInLoanCurrency,
    minLoan: minLoanInLoanCurrency,
  });

  // loan details
  const { detailsHidden, toggleDetails, loanDetailsProps } = useLoanDetails({
    collateralBigFloat,
    collateralCurrency:
      isHomePageCalculator && collateralCurrency === "wBTC"
        ? "BTC"
        : collateralCurrency,
    collateralToLoanRate,
    secondlyRate: adapter.secondlyRate,
    loanAmountPlusFee,
    loanAmountInDai,
    loanAmountInDaiPlusFee,
    loanCurrency,
    loanFee,
    liquidationPrice,
    daiToNokRate,
    liqLtv,
    maxLtv,
    ltv,
    noDai,
    daiToUsdRate,
  });

  return {
    loanCurrencies,
    loanCurrency,
    setLoanCurrency,
    loanToDaiRate,
    collateralToLoanRate,
    collateral,
    collateralError,
    onCollateralChange,
    collateralConversionText,
    changeCollateral,
    loanAmount,
    loanAmountBigFloat,
    loanAmountError,
    setLoanAmount,
    onLoanAmountChange,
    loanAmountPlusFee,
    minLoanInLoanCurrency,
    maxLoanInLoanCurrency,
    currencySymbolBeforeInput,
    currencySymbolAfterInput,
    ltv,
    maxLtv,
    liqLtv,
    ltvValues,
    maxLtvTooltipText,
    liqLtvTooltipText,
    errors,
    catchErrors,
    sliderPos,
    sliderClassName,
    detailsHidden,
    toggleDetails,
    loanDetailsProps,
  };
}
