import { BigFloat } from "shared/build/src/utils/BigFloat";
import { SupportedLoanCurrencyId } from "shared/build/src/SupportedCurrencies";
import { LoanCurrencyRatesCollection } from "src/conversion/hooks";

export type CalculatorRates = {
  collateralToDaiRate: BigFloat;
  collateralToLoanRate: BigFloat;
  loanToDaiRate: BigFloat;
  daiToUsdRate: BigFloat;
  daiToNokRate: BigFloat;
};

export function useCalculatorRates(args: {
  initialCollateralToDaiRate: BigFloat;
  loanCurrency: SupportedLoanCurrencyId;
  loanCurrencyCollection: LoanCurrencyRatesCollection;
}): CalculatorRates {
  const {
    initialCollateralToDaiRate: collateralToDaiRate,
    loanCurrency,
    loanCurrencyCollection,
  } = args;

  const daiToLoanRate = loanCurrencyCollection.find(
    (rate) => rate.assetId == loanCurrency
  )?.rate;

  if (!daiToLoanRate)
    throw new Error(
      `Calculator not able to find ${loanCurrency} in loanCurrencyCollection`
    );

  const daiToNokRate =
    loanCurrency === "NOK"
      ? daiToLoanRate
      : loanCurrencyCollection.find((rate) => rate.assetId == "NOK")?.rate;

  if (!daiToNokRate)
    throw new Error(
      `Calculator not able to find NOK in loanCurrencyCollection`
    );

  const daiToUsdRate = loanCurrencyCollection.find(
    (rate) => rate.assetId == "USD"
  )?.rate;

  if (!daiToUsdRate)
    throw new Error(
      `Calculator not able to find USD in loanCurrencyCollection`
    );

  const loanToDaiRate = BigFloat.fromNumber(1).div(daiToLoanRate);
  const collateralToLoanRate = collateralToDaiRate.mul(daiToLoanRate);

  return {
    collateralToLoanRate,
    collateralToDaiRate,
    daiToNokRate,
    daiToUsdRate,
    loanToDaiRate,
  };
}
