import { ChangeEvent } from "react";
import { useFormField } from "src/forms/formHelpers";
import { BigFloat } from "shared/build/src/utils/BigFloat";
import { getLoanAmountFromLtv } from "src/loan/LoanFunctions";
import { SupportedLoanCurrencyId } from "shared/build/src/SupportedCurrencies";
import {
  formatFromDisplayString,
  formatToDisplayString,
} from "src/formatting/functions";
import { getInputCurrencySymbols } from "../functions";

export function useLoanAmount(args: {
  initialCollateral: BigFloat;
  collateralToDaiRate: BigFloat;
  loanToDaiRate: BigFloat;
  loanFee?: BigFloat;
  debtFloor: BigFloat;
  maxLtv: BigFloat;
  collateralBigFloat: BigFloat;
  loanCurrency: SupportedLoanCurrencyId;
}) {
  const {
    initialCollateral,
    collateralToDaiRate,
    collateralBigFloat,
    loanToDaiRate,
    loanFee,
    debtFloor,
    maxLtv,
    loanCurrency,
  } = args;

  const initialLoanAmount = getLoanAmountFromLtv({
    ltv: BigFloat.fromNumber(0.4),
    collateral: initialCollateral,
    price: collateralToDaiRate,
  }).div(loanToDaiRate);

  const [
    loanAmount,
    setLoanAmount,
    loanAmountError,
    validateLoanAmount,
    setLoanAmountError,
  ] = useFormField(formatToDisplayString(initialLoanAmount), "text", true);

  const loanAmountBigFloat = BigFloat.fromString(
    formatFromDisplayString(loanAmount)
  );

  // always needs to have a base dai amount for calculating dependent variables
  const loanAmountInDai = loanAmountBigFloat.mul(loanToDaiRate);

  const loanAmountPlusFee = loanFee
    ? loanAmountBigFloat.add(loanAmountBigFloat.mul(loanFee))
    : loanAmountBigFloat;

  const loanAmountInDaiPlusFee = loanAmountPlusFee.mul(loanToDaiRate);

  const minLoanInDai = debtFloor;

  const maxLoanInDai = (() => {
    const maxL = getLoanAmountFromLtv({
      ltv: maxLtv,
      collateral: collateralBigFloat,
      price: collateralToDaiRate,
    }).div(BigFloat.fromNumber(1).add(loanFee ?? BigFloat.zero()));
    return maxL.lessThan(minLoanInDai) ? minLoanInDai : maxL;
  })();

  const minLoanInLoanCurrency =
    loanCurrency === "DAI" || loanCurrency === "USD"
      ? minLoanInDai.toDecimals(0)
      : minLoanInDai.div(loanToDaiRate).toDecimals(0);

  const maxLoanInLoanCurrency = maxLoanInDai.div(loanToDaiRate).toDecimals(0);

  const onLoanAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLoanAmount(formatToDisplayString(e.target.value, false));
  };

  const catchLoanAmountErrors = () => {
    let loanAmountError = validateLoanAmount();

    if (loanAmountBigFloat.lessThan(minLoanInLoanCurrency)) {
      loanAmountError = "Loan amount under minimum";
    }

    if (loanAmountBigFloat.greaterThan(maxLoanInLoanCurrency)) {
      loanAmountError = "Loan amount above maximum";
    }

    setLoanAmountError(loanAmountError);

    return loanAmountError;
  };

  const { currencySymbolBeforeInput, currencySymbolAfterInput } =
    getInputCurrencySymbols(loanCurrency);

  return {
    loanAmount,
    setLoanAmount,
    loanAmountError,
    loanAmountBigFloat,
    loanAmountInDai,
    loanAmountPlusFee,
    loanAmountInDaiPlusFee,
    onLoanAmountChange,
    catchLoanAmountErrors,
    minLoanInLoanCurrency,
    maxLoanInLoanCurrency,
    currencySymbolBeforeInput,
    currencySymbolAfterInput,
  };
}
